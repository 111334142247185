import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchBar.css';

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const handleSearch = () => {
        if (searchTerm.trim() !== '') {
            navigate(`/listings/${encodeURIComponent(searchTerm)}`);
        }
    };

    return (
        <div className="search-bar">
            <div className="input-container">
                <input
                    type="text"
                    placeholder="Search cards"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button className="search-button" onClick={handleSearch}>
                    &gt;
                </button>
            </div>
        </div>
    );
};

export default SearchBar;
