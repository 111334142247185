import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Layout from '../components/Layout';
import OrderActionsModal from '../components/OrderActions/OrderActionsModal';
import { jwtDecode } from 'jwt-decode'; // Corrected named import


import './OrderDetail.css';

// Function to retrieve the auth token from cookies
function getAuthToken() {
    const match = document.cookie.match(new RegExp('(^| )' + 'authToken' + '=([^;]+)'));
    return match ? match[2] : null;
}

const OrderDetail = () => {
    const { orderNumber } = useParams();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSeller, setIsSeller] = useState(false);
    const [trackingNumber, setTrackingNumber] = useState('');
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchOrder = async () => {
        const token = getAuthToken();
    
        try {
            const response = await fetch(`${apiBaseUrl}/orders?orderNumber=${orderNumber}`, {
                method: 'GET',
                credentials: 'include', // Ensures cookies are sent with cross-origin requests
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
    
            const result = await response.json();
            if (result.orders && result.orders.length > 0) {
                const orderData = result.orders[0];
                setOrder(orderData);
    
                // If the token exists, decode it to check the user's email
                if (token) {
                    const decodedToken = jwtDecode(token);
                    const userEmail = decodedToken.sub;
    
                    // Check if the logged-in user is the seller
                    setIsSeller(userEmail === orderData.seller_email);
                } else {
                    // If no token, user is unauthenticated
                    setIsSeller(false);
                }
            } else {
                throw new Error('Order not found');
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        window.scrollTo(0, 0);
        fetchOrder();
    }, [orderNumber]);

    const handleConfirmOrder = async () => {
        if (!isSeller) return;

        const token = getAuthToken();
        try {
            const response = await fetch(`${apiBaseUrl}/orders/confirmation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    order_number: orderNumber,
                    source: 'orders-page',
                }),
            });

            if (!response.ok) {
                throw new Error('Order confirmation failed');
            } else {
                alert('Order confirmed successfully!');
                fetchOrder(); // Refresh order details
            }
        } catch (error) {
            console.error('Order confirmation error:', error);
            alert('Error confirming order.');
        }
    };

    const handleFulfillOrder = async () => {
        if (!isSeller || !trackingNumber) return;

        const token = getAuthToken();
        try {
            const response = await fetch(`${apiBaseUrl}/orders/fulfillment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    order_number: orderNumber,
                    source: 'orders-page',
                    tracking_number: trackingNumber,
                }),
            });

            if (!response.ok) {
                throw new Error('Order fulfillment failed');
            } else {
                alert('Order fulfilled successfully!');
                fetchOrder(); // Refresh order details
            }
        } catch (error) {
            console.error('Order fulfillment error:', error);
            alert('Error fulfilling order.');
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading order: {error}</p>;
    if (!order) return <p>No order found</p>;

    const { buyer_email, price, order_number, cert_number } = order;

    const imageUrl = `https://gradies.blob.core.windows.net/psa-images/${cert_number}/front-${cert_number}.jpg`;

    return (
        <div>
            <Header setSupertype={() => {}} setType={() => {}} resetListings={() => {}} />
            <Layout>
                <div className="order-detail">
                    <div className="order-info glass-pane">
                        <h1>Order #{order_number}</h1>
                        <p><strong>Buyer Email:</strong> {buyer_email}</p>
                        <div className="price-details">
                            <p>Price: <span>${price.toFixed(2)}</span></p>
                            <hr />
                            <p className="total">Total: <span>${(price).toFixed(2)}</span></p>
                        </div>
                        {/* Show actions only if logged-in user is the seller */}
                        {isSeller && (
                            <OrderActionsModal
                                onConfirm={handleConfirmOrder}
                                onFulfill={handleFulfillOrder}
                                trackingNumber={trackingNumber}
                                setTrackingNumber={setTrackingNumber}
                                orderConfirmationId={order?.order_confirmation_id}
                                orderFulfillmentId={order?.order_fulfillment_id}
                            />
                        )}
                    </div>
                    <div className="order-image">
                        <img src={imageUrl} alt={`PSA Graded Card ${cert_number}`} />
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default OrderDetail;
