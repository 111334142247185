import React, { useState, useEffect } from 'react';
import './AdminContent.css';

const AdminContent = ({ selectedSection, certNumber }) => {
    const [listings, setListings] = useState([]);
    const [cert, setCert] = useState(null);
    const [cards, setCards] = useState([]);
    const [orders, setOrders] = useState([]);
    const [sets, setSets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [searchCertNumber, setSearchCertNumber] = useState(certNumber || '');
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        setLoading(true);
        setError(null);

        if (selectedSection === 'Listings') {
            fetchListings();
        } else if (selectedSection === 'Certs') {
            if (searchCertNumber) {
                fetchCert(searchCertNumber);
            } else {
                setLoading(false); // Stop loading if there's no cert number to search
            }
        } else if (selectedSection === 'Cards') {
            fetchCards();
        } else if (selectedSection === 'Orders') {
            fetchOrders();
        } else if (selectedSection === 'Sets') {
            fetchSets();
        } else {
            setLoading(false);
        }
    }, [selectedSection, page, searchCertNumber]);

    const fetchListings = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/listings?page=${page}`);
            if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`);
            const result = await response.json();
            setListings(result.listings);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchCert = async (certNumber) => {
        try {
            const response = await fetch(`${apiBaseUrl}/cert?certNumber=${certNumber}`);
            if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`);
            const result = await response.json();
            setCert(result);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchCards = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/cards?page=${page}`);
            if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`);
            const result = await response.json();
            setCards(result.cards); // Adjust if the API response differs
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchOrders = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/orders?page=${page}`);
            if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`);
            const result = await response.json();
            setOrders(result.orders); // Adjust if the API response differs
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchSets = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/sets?page=${page}`);
            if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`);
            const result = await response.json();
            setSets(result); // Directly setting result since Postman shows the array is at the root
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleCertNumberClick = (certNumber) => {
        setSearchCertNumber(certNumber);
        fetchCert(certNumber);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div className="admin-content">
            <h2>{selectedSection}</h2>

            {selectedSection === 'Certs' && (
                <div>
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Search by Cert Number..."
                            value={searchCertNumber}
                            onChange={(e) => setSearchCertNumber(e.target.value)}
                        />
                        <button onClick={() => fetchCert(searchCertNumber)}>Search</button>
                    </div>
                    {cert && (
                        <div className="cert-details">
                            <h3>Cert Details</h3>
                            <p><strong>ID:</strong> {cert.id}</p>
                            <p><strong>Card ID:</strong> {cert.card_id}</p>
                            <p><strong>Cert Number:</strong> {cert.cert_number}</p>
                            <p><strong>Grade:</strong> {cert.grade}</p>
                            <p><strong>Name:</strong> {cert.name}</p>
                            <p><strong>Set:</strong> {cert.set}</p>
                            <p><strong>Set Number:</strong> {cert.set_number}</p>
                            <p><strong>Year:</strong> {cert.year}</p>
                            <p><strong>Total Population:</strong> {cert.total_population}</p>
                            <div className="cert-images">
                                <img 
                                    src={cert.cert_images.front_image_url} 
                                    alt="Front" 
                                    className="cert-image" 
                                    onClick={() => window.open(`https://gradies.com/listing/${cert.cert_number}`, '_blank')}
                                />
                                <img 
                                    src={cert.cert_images.back_image_url} 
                                    alt="Back" 
                                    className="cert-image" 
                                    onClick={() => window.open(`https://gradies.com/listing/${cert.cert_number}`, '_blank')}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}

            {selectedSection === 'Listings' && listings.length > 0 && (
                <table className="listings-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Listing ID</th>
                            <th>Seller Email</th>
                            <th>Cert Number</th>
                            <th>Card Name</th>
                            <th>Price</th>
                            <th>Wholesale Price</th>
                            <th>Is Active</th>
                            <th>Card ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listings.map((listing) => (
                            <tr key={listing.id}>
                                <td>{listing.id}</td>
                                <td>{listing.listing_id}</td>
                                <td>{listing.seller_email}</td>
                                <td>
                                    <a href="#" onClick={() => handleCertNumberClick(listing.cert_number)}>
                                        {listing.cert_number}
                                    </a>
                                </td>
                                <td>{listing.card_name}</td>
                                <td>{listing.price}</td>
                                <td>{listing.wholesale_price}</td>
                                <td>{listing.is_active ? 'Yes' : 'No'}</td>
                                <td>{listing.card_id}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {selectedSection === 'Cards' && cards.length > 0 && (
                <table className="cards-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Supertype</th>
                            <th>HP</th>
                            <th>Rarity</th>
                            <th>Artist</th>
                            <th>Set Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cards.map((card) => (
                            <tr key={card.id}>
                                <td>{card.id}</td>
                                <td>{card.name}</td>
                                <td>{card.supertype}</td>
                                <td>{card.hp}</td>
                                <td>{card.rarity}</td>
                                <td>{card.artist}</td>
                                <td>{card.set.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {selectedSection === 'Orders' && orders.length > 0 && (
                <table className="orders-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Buyer Email</th>
                            <th>Seller Email</th>
                            <th>Cert Number</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order) => (
                            <tr key={order.id}>
                                <td>{order.id}</td>
                                <td>{order.buyer_email}</td>
                                <td>{order.seller_email}</td>
                                <td>{order.cert_number}</td>
                                <td>{order.price}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {selectedSection === 'Sets' && sets.length > 0 && (
                <table className="sets-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Series</th>
                            <th>Printed Total</th>
                            <th>Total</th>
                            <th>PTCGO Code</th>
                            <th>Release Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sets.map((set) => (
                            <tr key={set.id}>
                                <td>{set.id}</td>
                                <td>{set.name}</td>
                                <td>{set.series}</td>
                                <td>{set.printed_total}</td>
                                <td>{set.total}</td>
                                <td>{set.ptcgo_code}</td>
                                <td>{new Date(set.release_date).toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {loading && <p>Loading...</p>}
            {!loading && (listings.length === 0 && cert === null && cards.length === 0 && orders.length === 0 && sets.length === 0) && <p>No data available for this section.</p>}
        </div>
    );
};

export default AdminContent;
