import React from 'react';
import './BrokerSidebar.css';

const BrokerSidebar = ({ onSectionSelect }) => {
    const sections = ['Certs', 'Listings', 'Orders']; // Added 'Listings' to the sections

    return (
        <div className="broker-sidebar">
            <ul>
                {sections.map((section) => (
                    <li key={section} onClick={() => onSectionSelect(section)}>
                        {section}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BrokerSidebar;
