import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import PostPurchaseModal from '../components/PostPurchase/PostPurchaseModal';
import './ListingDetail.css';

const loadPayPalScript = () => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        const clientid = process.env.REACT_APP_PAYPAL_CLIENT_ID;
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientid}&currency=USD&components=buttons&enable-funding=card&disable-funding=paylater&intent=authorize`;
        script.onload = () => resolve(true);
        script.onerror = () => resolve(false);
        document.body.appendChild(script);
    });
};

const formatDate = (dateString) => {
    if (!dateString) {
        return ''; // Return empty string if dateString is not provided
    }

    try {
        const options = {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
            timeZone: 'America/New_York',
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
        
        // Split and format
        const [date, timeWithAmpm] = formattedDate.split(', ');
        const [time, ampm] = timeWithAmpm.split(' ');

        return `${date.replace(/\//g, '-')} ${time} ${ampm.toLowerCase()} EST`;
    } catch (error) {
        console.error('Failed to format date:', error);
        return ''; // Return empty string on failure
    }
};


const ListingDetail = () => {
    const { certNumber } = useParams();
    const navigate = useNavigate();
    const [listing, setListing] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [flavorTextDisplay, setFlavorTextDisplay] = useState('');
    const [isExpanded, setIsExpanded] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [orderNumber, setOrderNumber] = useState(null); // Add this line to initialize orderNumber state

    const createOrderOnBackend = async (paypalOrderID) => {
        const orderPayload = {
          price: listing.price,
          cert_number: certNumber,
          paypal_order_id: paypalOrderID,
          // Include any additional order details you have available
          // If you have buyer information collected separately, include it here
        };
      
        try {
          const response = await fetch(`${apiBaseUrl}/orders`, {
            method: 'POST',
            credentials: 'include', // Ensures cookies are sent with cross-origin requests
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(orderPayload),
          });
      
          if (response.ok) {
            const data = await response.json();
            setOrderNumber(data.order_number);
            setShowSuccessModal(true);
          } else {
            const errorData = await response.json();
            console.error('Order creation failed:', errorData);
          }
        } catch (error) {
          console.error('Order creation error:', error);
        }
      };

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchListing = async () => {
            try {
                const response = await fetch(`${apiBaseUrl}/listings?certNumber=${certNumber}`, {
                    method: 'GET',
                    credentials: 'include', // Ensures cookies are sent with cross-origin requests
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.statusText}`);
                }

                const result = await response.json();
                if (result.status === 'success' && result.listings.length > 0) {
                    setListing(result.listings[0]);
                } else {
                    throw new Error('Listing not found');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchListing();
    }, [certNumber]);

    useEffect(() => {
        if (listing && listing.cert.card.flavor_text) {
            let i = 0;
            const text = listing.cert.card.flavor_text;
            const interval = setInterval(() => {
                setFlavorTextDisplay((prev) => prev + text[i]);
                i++;
                if (i === text.length) clearInterval(interval);
            }, 30);
        }
    }, [listing]);

    useEffect(() => {
        const renderPayPalButtons = async () => {
          const loaded = await loadPayPalScript();
          if (loaded && window.paypal) {
            window.paypal.Buttons({
              createOrder: (data, actions) => {
                // Create the order on PayPal but do not capture it
                return actions.order.create({
                  purchase_units: [{
                    amount: {
                      value: listing.price.toFixed(2),
                    },
                  }],
                });
              },
              onApprove: (data, actions) => {
                // Payment approved by buyer, send orderID to backend
                createOrderOnBackend(data.orderID);
              },
              onError: (err) => {
                console.error('PayPal Checkout Error:', err);
              },
              style: {
                color: 'blue' // Options: 'gold', 'blue', 'silver', 'white', 'black'
              }
            }).render('#paypal-button-container');
          } else {
            console.error('PayPal SDK failed to load.');
          }
        };
      
        if (listing) {
          renderPayPalButtons();
        }
      }, [listing]);
      
      

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading listing: {error}</p>;
    if (!listing) return <p>No listing found</p>;

    const { cert, price, cert: { card } } = listing;
    const frontImageUrl = cert.cert_images.front_image_url || 'https://via.placeholder.com/200';
    const grade = cert.grade || 'N/A';
    const name = card?.name || cert.name || 'Unknown';
    const certSet = cert.set || 'Unknown';
    const cardSet = card?.set || 'Unknown';
    const flavorText = card?.flavor_text || '';
    const illustrator = card?.artist || 'Unknown';

    const togglePane = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div>
            <Header setSupertype={() => {}} setType={() => {}} resetListings={() => {}} />
            <Layout>
            <div className="listing-detail">
                    <div
                        className={`listing-info glass-pane ${isExpanded ? 'expanded' : 'collapsed'}`}
                        onClick={togglePane}
                    >
                        <div className="name-grade">
                            <h1><i>{name}</i></h1>
                            <span className="grade">{grade}</span>
                        </div>
                        <div className="details-container">
                            <div className="card-details">
                                <p><i>{cardSet.series === cardSet.name ? cardSet.name : cardSet.series + ": " + cardSet.name}</i></p>
                                <p className="illustrator">Illustrated by <Link to={`/artists/${illustrator}`}>{illustrator}</Link></p>
                            </div>
                            <div className="pricecharting-container">
                            <div
                                className="pricecharting-widget"
                                onClick={() =>
                                    cert.pricing_data && cert.price_charting_id
                                        ? window.open(`https://www.pricecharting.com/game/${cert.price_charting_id}`, '_blank')
                                        : null
                                }
                                style={{ cursor: cert.pricing_data && cert.price_charting_id ? 'pointer' : 'default' }}
                            >
                            <h3>PriceCharting</h3>
                            {cert.pricing_data ? (
                            <>
                                <div className="price-row">
                                    <span className="label">PSA 10</span>
                                    <span className="colon">:</span>
                                    <span className="price">
                                        {cert.pricing_data.psa_10
                                            ? `$${cert.pricing_data.psa_10 % 1 === 0 ? cert.pricing_data.psa_10.toFixed(0) : cert.pricing_data.psa_10.toFixed(2)}`
                                            : '--'}
                                    </span>
                                </div>
                                <div className="price-row">
                                    <span className="label">PSA 9</span>
                                    <span className="colon">:</span>
                                    <span className="price">
                                        {cert.pricing_data.psa_9
                                            ? `$${cert.pricing_data.psa_9 % 1 === 0 ? cert.pricing_data.psa_9.toFixed(0) : cert.pricing_data.psa_9.toFixed(2)}`
                                            : '--'}
                                    </span>
                                </div>
                                <div className="price-row">
                                    <span className="label">PSA 8</span>
                                    <span className="colon">:</span>
                                    <span className="price">
                                        {cert.pricing_data.psa_8
                                            ? `$${cert.pricing_data.psa_8 % 1 === 0 ? cert.pricing_data.psa_8.toFixed(0) : cert.pricing_data.psa_8.toFixed(2)}`
                                            : '--'}
                                    </span>
                                </div>
                                <div className="price-row">
                                    <span className="label">PSA 7</span>
                                    <span className="colon">:</span>
                                    <span className="price">
                                        {cert.pricing_data.psa_7
                                            ? `$${cert.pricing_data.psa_7 % 1 === 0 ? cert.pricing_data.psa_7.toFixed(0) : cert.pricing_data.psa_7.toFixed(2)}`
                                            : '--'}
                                    </span>
                                </div>

                            </>
                        ) : (
                            <p>No pricing data available</p> // Optional fallback message
                        )}
                        {cert.pricing_data?.date_updated && (
                                    <div className="date-updated">
                                        {formatDate(cert.pricing_data.date_updated)}
                                    </div>
                                )}
                                    </div>
                                    
                                </div>
                                
                            </div>
                            {flavorText && (
                                <div className="flavor-text-box">
                                    <p className="flavor-text"><i>{flavorTextDisplay}</i></p>
                                </div>
                            )}
                            <div className="checkout">
                                <div className="price-details">
                                    <p>Price <span>${price.toFixed(2)}</span></p>
                                    <hr />
                                    <p className="total">Total <span>${(price).toFixed(2)}</span></p>
                                </div>
                                <div id="paypal-button-container"></div>
                            </div>
                        </div>
                    <div className="listing-image">
                        <a href={`https://www.psacard.com/cert/${certNumber}/psa`} target="_blank" rel="noopener noreferrer">
                            <img src={frontImageUrl} alt={name} />
                        </a>
                    </div>
                </div>
                {showSuccessModal && (
                    <PostPurchaseModal onConfirm={() => navigate(`/orders/${orderNumber}`)} />
)               }
            </Layout>
        </div>
    );
};

export default ListingDetail;
