import React, { useState, useEffect } from 'react';
import SearchBar from '../SearchBar';
import './Header.css';
import { useLocation, useNavigate } from 'react-router-dom';
import loginIcon from '../assets/login.png';

const Header = ({ setSupertype, setType, resetListings }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLogoClick = (e) => {
        e.preventDefault();
        if (location.pathname !== '/') {
            navigate('/');
        } else {
            setSupertype('');
            setType('');
            resetListings();
        }
    };

    const handleSupertypeClick = (supertype) => {
        navigate(`/${supertype.toLowerCase()}`);
    };

    return (
        <header className="header">
            <a href="/" className="logo" onClick={handleLogoClick}>
                {isMobile ? <span className="mobile-logo">g</span> : <span className="full-logo">gradies</span>}
            </a>
            <SearchBar />
            {!isMobile && (
                <nav className="nav">
                    <button onClick={() => handleSupertypeClick('Pokemon')}>Pokemon</button>
                    <button onClick={() => handleSupertypeClick('Trainer')}>Trainers</button>
                    <button onClick={() => handleSupertypeClick('Energy')}>Energy</button>
                    <a href="/profile"><i className="fa fa-user-circle"></i></a>
                    <img src={loginIcon} alt="Login" className="login-icon" />
                </nav>
            )}
        </header>
    );
};

export default Header;
