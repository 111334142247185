import React from 'react';
import Card from '../Card'; // Importing Card directly
import './Binder.css'; // Adjust styles here to support horizontal scrolling

const Binder = ({ listings }) => {
    return (
        <div className="binder">
            <div className="binder-scroll">
                {listings.map((listing, index) => (
                    <Card key={index} card={listing.cert} price={listing.price} />
                ))}
            </div>
        </div>
    );
};

export default Binder;
