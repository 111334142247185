import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BrokerSidebar from '../components/BrokerSidebar';
import BrokerContent from '../components/BrokerContent';
import Header from '../../components/Header';
import './BrokerDashboard.css';

const BrokerDashboard = ({ setSupertype, setType, resetListings }) => {
    const location = useLocation();
    const [selectedSection, setSelectedSection] = useState('Certs'); // Default to 'Certs'
    const [certNumber, setCertNumber] = useState('');

    const handleSectionSelect = (section) => {
        setSelectedSection(section);
    };

    useEffect(() => {
        // Handle navigation with state for section and certNumber
        if (location.state) {
            setSelectedSection(location.state.section || 'Certs');
            setCertNumber(location.state.certNumber || '');
        }
    }, [location.state]);

    return (
        <div className="broker-dashboard">
            {/* Include header */}
            <Header setSupertype={setSupertype} setType={setType} resetListings={resetListings} />
            <div className="broker-main">
                {/* Sidebar for section selection */}
                <BrokerSidebar 
                    onSectionSelect={handleSectionSelect} 
                    selectedSection={selectedSection} 
                />
                {/* Content area for the selected section */}
                <BrokerContent 
                    selectedSection={selectedSection} 
                    setSelectedSection={setSelectedSection}
                    certNumber={certNumber} 
                />
            </div>
        </div>
    );
};

export default BrokerDashboard;
