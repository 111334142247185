import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Card.css';

const Card = ({ card, price }) => {
    const navigate = useNavigate();
    const certNumber = card?.cert_number;

    const handleClick = () => {
        navigate(`/listing/${certNumber}`);
    };

    const frontImageUrl = card?.cert_images?.front_image_url || 'https://via.placeholder.com/200';
    const name = card?.card?.name || 'Unknown';
    const finalPrice = Math.ceil(price); // Adjust based on your pricing logic

    return (
        <div className="card" onClick={handleClick}>
            <img className="card-image" src={frontImageUrl} alt={name} />
            <div className="card-details">
                <div className="card-info">
                    <span className="card-name">{name}</span>
                    <span className="card-price">${finalPrice.toLocaleString()}</span>
                </div>
            </div>
        </div>
    );
};

export default Card;
