import { jwtDecode } from 'jwt-decode';
import React, { useState, useEffect } from 'react';
import './BrokerContent.css';
import OrdersPage from './OrdersPage'; // Import the OrdersPage component

const BrokerContent = ({ selectedSection, setSelectedSection, certNumber }) => {
    const [psaOrders, setPsaOrders] = useState([]);
    const [certs, setCerts] = useState([]);
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [rowPrices, setRowPrices] = useState({});
    const [disabledRows, setDisabledRows] = useState({}); // Tracks rows being processed
    const [page, setPage] = useState(1); // Tracks current page
    const [totalPages, setTotalPages] = useState(1); // Tracks total pages from API
    const [selectedPsaOrder, setSelectedPsaOrder] = useState(null); // Tracks the selected PSA Order
    
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    // State for modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImageSrc, setModalImageSrc] = useState('');

    const getTokenFromCookie = () => {
        const value = `; ${document.cookie}`;
        const parts = value.split('; authToken=');
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    const getBrokerEmail = () => {
        const token = getTokenFromCookie();
        if (!token) {
            setError('Authentication token not found.');
            return null;
        }

        try {
            const decoded = jwtDecode(token);
            return decoded.sub; // Assuming the email is stored in the 'sub' claim
        } catch (err) {
            setError('Failed to decode authentication token.');
            return null;
        }
    };


    const setRowDisabled = (rowKey, isDisabled) => {
        setDisabledRows((prev) => ({
            ...prev,
            [rowKey]: isDisabled,
        }));
    };
    
    const removeRowFromView = (rowKey) => {
        setCerts((prevCerts) => prevCerts.filter((cert) => cert.cert_number !== rowKey));
        setListings((prevListings) =>
            prevListings.filter((listing) => listing.cert?.cert_number !== rowKey)
        );
    };
    
    const handleImageClick = (imageUrl) => {
        setModalImageSrc(imageUrl);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    
    useEffect(() => {
        // Fetch PSA Orders on component load
        fetchPsaOrders();
    }, []);
    
    useEffect(() => {
        if (selectedSection === 'Certs') {
            if (selectedPsaOrder) {
                fetchCerts(selectedPsaOrder); // Fetch certs when a PSA Order is selected
            } else {
                setCerts([]); // Clear certs if no PSA Order is selected
            }
        } else if (selectedSection === 'Listings') {
            if (selectedPsaOrder) {
                fetchListings(page); // Fetch listings when a PSA Order is selected
            } else {
                setListings([]); // Clear listings if no PSA Order is selected
            }
        }
    }, [selectedSection, selectedPsaOrder, page]);
    
    const fetchPsaOrders = async () => {
        setLoading(true);
        setError(null);

        try {
            const email = getBrokerEmail();
            if (!email) return;

            const response = await fetch(`${apiBaseUrl}/psaOrders?brokerEmail=${email}`);
            if (!response.ok) {
                throw new Error(`Failed to load PSA Orders: ${response.statusText}`);
            }

            const result = await response.json();
            setPsaOrders(result.psa_orders || []);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchCerts = async (orderNumber) => {
        if (!orderNumber) {
            setCerts([]); // Clear certs if no PSA Order is selected
            return;
        }
    
        setLoading(true);
        setError(null);
    
        try {
            const response = await fetch(`${apiBaseUrl}/certs?psaOrderNumber=${orderNumber}`);
            if (!response.ok) throw new Error(`Failed to load certs: ${response.statusText}`);
            const result = await response.json();
            // Filter out certs with listings
            const filteredCerts = result.certs.filter(cert => 
                !cert.listings || 
                cert.listings.length === 0 || 
                cert.listings.every(listing => !listing.is_active)
            );                                           
            // Clear disabled state for certs that no longer have active listings
            const enabledCertNumbers = filteredCerts.map((cert) => cert.cert_number);
            setDisabledRows((prev) => {
                const updatedDisabledRows = { ...prev };
                enabledCertNumbers.forEach((certNumber) => {
                    if (certNumber in updatedDisabledRows) {
                        delete updatedDisabledRows[certNumber];
                    }
                });
                return updatedDisabledRows;
            });

            setCerts(filteredCerts);
            // Prepopulate rowPrices with the bolded price or default to the first price
            const prices = {};
            filteredCerts.forEach((cert) => {
                const gradeLabel = cert.grade ? parseInt(cert.grade.match(/\d+/)?.[0], 10) : null;
                prices[cert.cert_number] =
                    cert.pricing_data && gradeLabel
                        ? cert.pricing_data[`psa_${gradeLabel}`]
                        : cert.price_charting_price || '';
            });
            setRowPrices(prices);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    
    const fetchListings = async (page = 1, count = 20) => {
        if (!selectedPsaOrder) return; // Do nothing if no PSA Order is selected
    
        setLoading(true);
        setError(null);
    
        try {
            const email = getBrokerEmail();
            if (!email) return;
    
            // Add psaOrderNumber filter if a PSA Order is selected
            const response = await fetch(
                `${apiBaseUrl}/listings?sellerEmail=${email}&psaOrderNumber=${selectedPsaOrder}&page=${page}&count=${count}`
            );
    
            if (!response.ok) throw new Error(`Failed to load listings: ${response.statusText}`);
            const result = await response.json();
    
            setListings(result.listings || []);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    
    
    const handlePriceChange = (certNumber, price) => {
        setRowPrices((prev) => ({
            ...prev,
            [certNumber]: price,
        }));
    };

    const handleUpdate = async (listingId, price) => {
        setRowDisabled(listingId, true);
        try {
            const response = await fetch(`${apiBaseUrl}/listings`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    price: parseFloat(price),
                    listing_id: listingId,
                }),
            });
    
            if (response.ok) {
                alert(`Successfully updated listing #${listingId}.`);
            } else {
                alert(`Failed to update listing #${listingId}: ${response.statusText}`);
            }
        } catch (error) {
            alert(`Error updating listing #${listingId}: ${error.message}`);
        } finally {
            setRowDisabled(listingId, false);
        }
    };

    const handleList = async (certNumber, price) => {
        const sellerEmail = getBrokerEmail();
        if (!sellerEmail) {
            alert('Failed to fetch seller email.');
            return;
        }

        const body = {
            seller_email: sellerEmail,
            price: parseFloat(price),
            cert_number: certNumber,
        };

        try {
            const response = await fetch(`${apiBaseUrl}/listings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (response.ok) {
                alert(`Successfully listed Cert #${certNumber} for $${price}.`);
                // Refetch certs after successful listing
                fetchCerts();
            } else {
                alert(`Failed to list Cert #${certNumber}: ${response.statusText}`);
            }
        } catch (error) {
            alert(`Error listing Cert #${certNumber}: ${error.message}`);
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p className="error-message">{error}</p>;

    return (
        <div className="broker-content">
            <h2>{selectedSection}</h2>
            {selectedSection === 'Certs' && (
                <div>
                    {/* PSA Order Buttons */}
                    {psaOrders.length > 0 && (
                        <div className="psa-orders-grid">
                        {psaOrders.map((order) => (
                            <button
                                key={order.id}
                                className={`psa-order-button ${selectedPsaOrder === order.psa_order_number ? 'active' : ''}`}
                                onClick={() => setSelectedPsaOrder(order.psa_order_number)}
                            >
                                {order.psa_order_number}
                            </button>
                        ))}
                    </div>
                    
                    
                    )}

                    {/* Certs Table */}
                    <table className="certs-table">
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Cert Number</th>
                                <th>Price</th>
                                <th>List</th>
                                <th>Hide</th>
                                <th>Name</th>
                                <th>Grade</th>
                                <th>PriceCharting Price</th>
                                <th>GameStop Pro Cash</th>
                                <th>PriceCharting Product</th>
                                <th>Card Set Name</th>
                                <th>Set #</th>
                            </tr>
                        </thead>
                        <tbody>
    {certs.map((cert) => {
        // Determine the appropriate PriceCharting price based on the cert's grade
        const grade = cert.grade ? parseInt(cert.grade.match(/\d+/)?.[0], 10) : null;
        const defaultPrice =
            grade && cert.pricing_data
                ? cert.pricing_data[`psa_${grade}`] || '' // Use the matched PSA grade price or default to empty
                : '';

        return (
            <tr key={cert.cert_number} className={disabledRows[cert.cert_number] ? 'disabled' : ''}>
                <td>
                    {cert.cert_images?.front_image_url && (
                        <img
                            src={cert.cert_images.front_image_url}
                            alt="Cert Front"
                            className="cert-image"
                            onClick={() => handleImageClick(cert.cert_images.front_image_url)}
                        />
                    )}
                </td>
                <td>{cert.cert_number}</td>
                <td>
                    <input
                        type="number"
                        className="price-input"
                        value={rowPrices[cert.cert_number] || defaultPrice}
                        onChange={(e) => handlePriceChange(cert.cert_number, e.target.value)}
                    />
                </td>
                <td>
                    <button
                        className="list-button"
                        disabled={disabledRows[cert.cert_number]}
                        onClick={async () => {
                            setRowDisabled(cert.cert_number, true);
                            try {
                                const response = await fetch(`${apiBaseUrl}/listings`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({
                                        seller_email: getBrokerEmail(),
                                        price: parseFloat(rowPrices[cert.cert_number] || defaultPrice),
                                        cert_number: cert.cert_number,
                                    }),
                                });

                                if (response.ok) {
                                    alert(`Successfully listed Cert #${cert.cert_number}.`);
                                    removeRowFromView(cert.cert_number); // Hide row
                                } else {
                                    alert(`Failed to list Cert #${cert.cert_number}: ${response.statusText}`);
                                    setRowDisabled(cert.cert_number, false); // Un-gray on failure
                                }
                            } catch (error) {
                                alert(`Error listing Cert #${cert.cert_number}: ${error.message}`);
                                setRowDisabled(cert.cert_number, false); // Un-gray on failure
                            }
                        }}
                    >
                        List
                    </button>
                </td>
                <td>
                    <button
                        className="hide-button"
                        disabled={disabledRows[cert.cert_number]}
                        onClick={async () => {
                            setRowDisabled(cert.cert_number, true);
                            try {
                                const response = await fetch(
                                    `${apiBaseUrl}/certs/deactivate?certNumber=${encodeURIComponent(cert.cert_number)}`,
                                    {
                                        method: 'DELETE',
                                    }
                                );

                                if (response.ok) {
                                    alert(`Successfully hid Cert #${cert.cert_number}.`);
                                    removeRowFromView(cert.cert_number); // Hide row
                                } else {
                                    alert(`Failed to hide Cert #${cert.cert_number}: ${response.statusText}`);
                                    setRowDisabled(cert.cert_number, false); // Un-gray on failure
                                }
                            } catch (error) {
                                alert(`Error hiding Cert #${cert.cert_number}: ${error.message}`);
                                setRowDisabled(cert.cert_number, false); // Un-gray on failure
                            }
                        }}
                    >
                        Hide
                    </button>
                </td>
                <td>{cert.name}</td>
                <td>{cert.grade ? cert.grade.match(/\d+/)?.[0] : 'N/A'}</td>
                <td>
                {cert.pricing_data ? (
                    <div className="price-charting-prices">
                        {['psa_7', 'psa_8', 'psa_9', 'psa_10']
                            .reverse()
                            .map((key, index) => {
                                const gradeLabel = 10 - index;
                                const isBold =
                                    cert.grade &&
                                    parseInt(cert.grade.match(/\d+/)?.[0], 10) === gradeLabel;

                                return (
                                    <div key={key} className={isBold ? 'bold-price' : ''}>
                                        {gradeLabel}: {cert.pricing_data[key] ? `$${cert.pricing_data[key]}` : 'N/A'}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        'N/A'
                )}
                </td>
                <td>
                    {cert.game_stop_pricing ? `$${cert.game_stop_pricing.offer_price_pro_cash}` : ''}
                </td>
                <td>
                    {cert.pricing_data?.product_name && cert.price_charting_id ? (
                        <a
                            href={`https://www.pricecharting.com/game/${cert.price_charting_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {cert.pricing_data.product_name}
                        </a>
                    ) : (
                        'N/A'
                    )}
                </td>
                <td>{cert.card?.set?.name}</td>
                <td>#{cert.set_number}</td>
            </tr>
        );
    })}
</tbody>

                    </table>
                </div>
            )}
            {selectedSection === 'Listings' && (
                <div>
                    {/* PSA Order Buttons */}
                    {psaOrders.length > 0 && (
                        <div className="psa-orders-grid">
                            {psaOrders.map((order) => (
                                <button
                                    key={order.id}
                                    className={`psa-order-button ${selectedPsaOrder === order.psa_order_number ? 'active' : ''}`}
                                    onClick={() => setSelectedPsaOrder(order.psa_order_number)}
                                >
                                    {order.psa_order_number}
                                </button>
                            ))}
                        </div>
                    )}

                    {/* Listings Table */}
                    <table className="certs-table">
                        <thead>
                            <tr>
                                <th>Image</th>
                                <th>Cert Number</th>
                                <th>Price</th>
                                <th>Update</th>
                                <th>Remove</th>
                                <th>Name</th>
                                <th>Grade</th>
                                <th>PriceCharting Price</th>
                                <th>GameStop Pro Cash</th>
                                <th>PriceCharting Product</th>
                                <th>Card Set Name</th>
                                <th>Set #</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listings.map((listing) => {
                                const cert = listing.cert;
                                return (
                                    <tr key={listing.id} className={disabledRows[cert?.cert_number] ? 'disabled' : ''}>
                                        <td>
                                            {cert?.cert_images?.front_image_url && (
                                                <img
                                                    src={cert.cert_images.front_image_url}
                                                    alt="Cert Front"
                                                    className="cert-image"
                                                    onClick={() => handleImageClick(cert.cert_images.front_image_url)}
                                                />
                                            )}
                                        </td>
                                        <td>{cert?.cert_number || 'N/A'}</td>
                                        <td>
                                            <input
                                                type="number"
                                                className="price-input"
                                                value={rowPrices[cert?.cert_number] || listing.price.toFixed(2)}
                                                onChange={(e) => handlePriceChange(cert?.cert_number, e.target.value)}
                                            />
                                        </td>
                                        <td>
                                        <button
                                            className="update-button"
                                            disabled={disabledRows[cert?.cert_number]}
                                            onClick={async () => {
                                                setRowDisabled(cert.cert_number, true);
                                                try {
                                                    const response = await fetch(`${apiBaseUrl}/listings`, {
                                                        method: 'PATCH',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                        },
                                                        body: JSON.stringify({
                                                            price: parseFloat(rowPrices[cert.cert_number]),
                                                            listing_id: listing.listing_id,
                                                        }),
                                                    });

                                                    if (response.ok) {
                                                        alert(`Successfully updated listing for Cert #${cert.cert_number}.`);
                                                        setRowDisabled(cert.cert_number, false); // Un-gray on success
                                                    } else {
                                                        alert(`Failed to update listing for Cert #${cert.cert_number}: ${response.statusText}`);
                                                        setRowDisabled(cert.cert_number, false); // Un-gray on failure
                                                    }
                                                } catch (error) {
                                                    alert(`Error updating listing for Cert #${cert.cert_number}: ${error.message}`);
                                                    setRowDisabled(cert.cert_number, false); // Un-gray on failure
                                                }
                                            }}
                                        >
                                            Update
                                        </button>
                                        </td>
                                        <td>
                                        <button
                                            className="remove-button"
                                            disabled={disabledRows[cert.cert_number]}
                                            onClick={async () => {
                                                setRowDisabled(cert.cert_number, true);
                                                try {
                                                    const response = await fetch(`${apiBaseUrl}/listings/deactivate?certNumber=${encodeURIComponent(cert.cert_number)}`, {
                                                        method: 'DELETE',
                                                    });

                                                    if (response.ok) {
                                                        alert(`Successfully removed listing for Cert #${cert.cert_number}.`);
                                                        removeRowFromView(cert.cert_number); // Hide row
                                                    } else {
                                                        alert(`Failed to remove listing for Cert #${cert.cert_number}: ${response.statusText}`);
                                                        setRowDisabled(cert.cert_number, false); // Un-gray on failure
                                                    }
                                                } catch (error) {
                                                    alert(`Error removing listing for Cert #${cert.cert_number}: ${error.message}`);
                                                    setRowDisabled(cert.cert_number, false); // Un-gray on failure
                                                }
                                            }}
                                        >
                                            Remove
                                        </button>

                                        </td>
                                        <td>{cert?.name || 'N/A'}</td>
                                        <td>{cert?.grade?.match(/\d+/)?.[0] || 'N/A'}</td>
                                        <td>
                                                    {cert.pricing_data ? (
                                                        <div className="price-charting-prices">
                                                            {['psa_7', 'psa_8', 'psa_9', 'psa_10'].reverse().map((key, index) => {
                                                                const gradeLabel = 10 - index;
                                                                const isBold =
                                                                    cert.grade &&
                                                                    parseInt(cert.grade.match(/\d+/)?.[0], 10) === gradeLabel;

                                                                return (
                                                                    <div
                                                                        key={key}
                                                                        className={isBold ? 'bold-price' : ''}
                                                                    >
                                                                        {gradeLabel}: {cert.pricing_data[key] ? `$${cert.pricing_data[key]}` : 'N/A'}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    ) : (
                                                        'N/A'
                                                    )}
                                                </td>
                                                <td>{cert.game_stop_pricing ? `$${cert.game_stop_pricing.offer_price_pro_cash}` : ''}</td>
                                        <td>
                                            {cert?.pricing_data?.product_name && cert?.price_charting_id ? (
                                                <a
                                                    href={`https://www.pricecharting.com/game/${cert.price_charting_id}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {cert.pricing_data.product_name}
                                                </a>
                                            ) : (
                                                'N/A'
                                            )}
                                        </td>
                                        <td>{cert?.card?.set?.name || 'N/A'}</td>
                                        <td>{cert?.set_number || 'N/A'}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <button onClick={() => setPage((prev) => Math.max(prev - 1, 1))} disabled={page === 1 || loading}>
                    Previous
                </button>
                <span>Page {page}</span>
                <button onClick={() => setPage((prev) => prev + 1)} disabled={loading}>
                    Next
                </button>
            </div>
            {selectedSection === 'Orders' && <OrdersPage />} {/* Render OrdersPage */}

            {isModalOpen && (
                <div className="image-modal-overlay show-modal" onClick={handleCloseModal}>
                    <img src={modalImageSrc} alt="Large Cert" className="image-modal-content" />
                </div>
            )}
        </div>
    );
};

export default BrokerContent;
