import React, { useState, useEffect } from 'react';
import './OrdersPage.css';

const OrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [trackingNumbers, setTrackingNumbers] = useState({});
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const gradiesBaseUrl = process.env.REACT_APP_GRADIES_BASE_URL; // Add Gradies base URL from environment variables

    const getTokenFromCookie = () => {
        const value = `; ${document.cookie}`;
        const parts = value.split('; authToken=');
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    const getBrokerEmail = () => {
        const token = getTokenFromCookie();
        if (!token) {
            setError('Authentication token not found.');
            return null;
        }

        try {
            const decoded = JSON.parse(atob(token.split('.')[1]));
            return decoded.sub; // Assuming email is in 'sub'
        } catch (err) {
            setError('Failed to decode authentication token.');
            return null;
        }
    };

    const fetchOrders = async () => {
        setLoading(true);
        setError(null);

        try {
            const sellerEmail = getBrokerEmail();
            if (!sellerEmail) return;

            const response = await fetch(`${apiBaseUrl}/orders?sellerEmail=${sellerEmail}`);
            if (!response.ok) throw new Error(`Failed to load orders: ${response.statusText}`);

            const result = await response.json();
            setOrders(result.orders || []);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleConfirm = async (orderNumber) => {
        try {
            const response = await fetch(`${apiBaseUrl}/confirmation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    order_number: orderNumber,
                    source: 'broker-dashboard',
                }),
            });

            if (response.ok) {
                alert(`Order ${orderNumber} confirmed.`);
                fetchOrders();
            } else {
                alert(`Failed to confirm order ${orderNumber}: ${response.statusText}`);
            }
        } catch (error) {
            alert(`Error confirming order ${orderNumber}: ${error.message}`);
        }
    };

    const handleFulfill = async (orderNumber) => {
        try {
            const response = await fetch(`${apiBaseUrl}/fulfillment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    order_number: orderNumber,
                    source: 'broker-dashboard',
                    tracking_number: trackingNumbers[orderNumber] || '',
                }),
            });

            if (response.ok) {
                alert(`Order ${orderNumber} fulfilled.`);
                fetchOrders();
            } else {
                alert(`Failed to fulfill order ${orderNumber}: ${response.statusText}`);
            }
        } catch (error) {
            alert(`Error fulfilling order ${orderNumber}: ${error.message}`);
        }
    };

    const handleTrackingNumberChange = (orderNumber, value) => {
        setTrackingNumbers((prev) => ({
            ...prev,
            [orderNumber]: value,
        }));
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p className="error-message">{error}</p>;

    return (
        <div className="orders-page">
            <h2>Orders</h2>
            <table className="orders-table">
                <thead>
                    <tr>
                        <th>Order Number</th>
                        <th>Buyer Email</th>
                        <th>Cert Number</th>
                        <th>Price</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order) => {
                        const { order_number, buyer_email, cert_number, price, order_confirmation_id, order_fulfillment_id } = order;

                        let rowClass = '';
                        if (!order_confirmation_id) rowClass = 'highlight-red';
                        else if (order_confirmation_id && !order_fulfillment_id) rowClass = 'highlight-yellow';
                        else if (order_confirmation_id && order_fulfillment_id) rowClass = 'highlight-green';

                        return (
                            <tr key={order_number} className={rowClass}>
                                <td>
                                    <a href={`https://gradies.com/orders/${order_number}`} target="_blank" rel="noopener noreferrer">
                                        {order_number}
                                    </a>
                                </td>
                                <td>{buyer_email}</td>
                                <td>{cert_number}</td>
                                <td>${price?.toFixed(2) || 'N/A'}</td>
                                <td>
                                    <button
                                        className="confirm-button"
                                        disabled={!!order_confirmation_id}
                                        onClick={() => handleConfirm(order_number)}
                                    >
                                        Confirm
                                    </button>
                                    <input
                                        type="text"
                                        placeholder="Tracking #"
                                        className="tracking-input"
                                        value={trackingNumbers[order_number] || ''}
                                        onChange={(e) => handleTrackingNumberChange(order_number, e.target.value)}
                                        disabled={!order_confirmation_id || !!order_fulfillment_id}
                                    />
                                    <button
                                        className="fulfill-button"
                                        disabled={!order_confirmation_id || !!order_fulfillment_id}
                                        onClick={() => handleFulfill(order_number)}
                                    >
                                        Fulfill
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default OrdersPage;
